<template>
    <v-card flat>
        <v-card-title>
            <div style="max-width: 600px" class="d-flex flex-column">
                <div class="d-flex align-center">
                    <span class="font-weight-bold secondary--text">Monitoring</span>
                    <v-chip class="ml-1" outlined small>Account Manager</v-chip>
                </div>
                <div class="mt-5">
                    <v-autocomplete
                        required
                        :items="availableResourcePools"
                        :disabled="disableResourcePoolSelector"
                        :loading="disableResourcePoolSelector"
                        v-model="resourcePoolName"
                        item-text="long_id"
                        item-value="rpid"
                        dense
                        label="Resource pool"
                        @change="changeResourcePoolData($event)"
                        outlined></v-autocomplete>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <the-user-resource-admin />
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
const TheUserResourceAdmin = () => import('../components/TheUserResourceAdmin')

export default {
    components: { TheUserResourceAdmin },
    data() {
        return {
            selectedTab: 0,
            dataForInstanceToInviteTo: null,
            resourcePoolName: '',
            mainTabNames: {
                OVERVIEW: 0,
                USAGE: 1,
                MANAGEMENT: 2,
                ADMIN: 3
            }
        }
    },
    methods: {
        changeResourcePoolData(rpid) {
            this.$store.dispatch('userStore/setSelectedResourcePool', rpid)
            this.$store.dispatch('userStore/fetchResourcePoolHpcUsers', rpid)
        }
    },
    computed: {
        ...mapState('userStore', [
            'fetchingAvailableResourcePools',
            'availableResourcePools',
            'isAccountManager',
            'fetchingResourceDescription',
            'fetchingResourceBalanceCurrentMonth',
            'fetchingResourceBalance',
            'fetchingResourceBalanceBreakdown',
            'selectedResourcePool'
        ]),
        tabs() {
            return [{ tabName: 'Admin', icon: 'admin_panel_settings', component: 'TheUserResourceAdmin' }]
        },
        isResourcePoolManager() {
            if (this.selectedResourcePool && this.availableResourcePools.length) {
                const selectedResourceData = this.availableResourcePools.find(resource => resource.rpid === this.selectedResourcePool).is_rp_manager
                return selectedResourceData
            }
            return false
        },
        disableResourcePoolSelector() {
            return (
                this.fetchingAvailableResourcePools ||
                this.fetchingResourceDescription ||
                this.fetchingResourceBalanceCurrentMonth ||
                this.fetchingResourceBalance ||
                this.fetchingResourceBalanceBreakdown
            )
        }
    },
    watch: {
        availableResourcePools: {
            handler: function (nextVal) {
                if (nextVal.length) {
                    this.$store.dispatch('userStore/setSelectedResourcePool', nextVal[0].rpid)
                    this.resourcePoolName = nextVal[0].rpid
                }
            },
            immediate: true
        },
        isResourcePoolManager: function (nextVal) {
            if (!nextVal) {
                this.selectedTab = this.mainTabNames.OVERVIEW
            }
        }
    },
    created() {
        this.$store.dispatch('userStore/fetchAccountManagerStatus')
    }
}
</script>
